import React from 'react'
import CTA from './CTA'
import './header.css'
import Logo from '../../assets/logo6.png'
import HeaderSocials from './HeaderSocials'

const Header = () => {
  return (
    <header>
      <div className="container header__container">
        <h5>Hey, I am</h5>
        <h1>nouhidev</h1>
        <h5 class="text-light">Indie Game Developer</h5>
        <CTA/>
        <HeaderSocials/>
        <div className="logo">
          <img src={Logo} alt="logo" />
        </div>

        <a href="#contact" className="scroll__down">Scroll Down</a>
      </div>
    </header>
  )
}

export default Header