import React from 'react'
import './projects.css'
import MED from '../../assets/my-eyes-deceive.jpg'

const Projects = () => {
  return (
    <section id="projects">
      <div class="horizontal"></div>
      <h5>What I've Worked On</h5>
      <h2>My Projects</h2>

      <div className="container projects__container">
        <article className='projects__items placeholder'>
        </article>
        <article className='projects__items'>
          <div className='projects__item-image'>
            <img src={MED} alt="my eyes deceive"></img>
          </div>
          <h3>my eyes deceive</h3>
          <h5>my eyes deceive is a first-person psychological horror experience set in a basement, or shelter, if you will. Set over the course of roughly a week, you play as a young girl who has been living there all her life.</h5>
          <div className='projects__item-cta'>
            <a href="https://nouhidev.itch.io/my-eyes-deceive" className='btn' target='_blank'>View</a>
          </div>
        </article>
        <article className='projects__items placeholder'>
        </article>
      </div>
    </section>
  )
}

export default Projects