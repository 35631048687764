import React from 'react'
import {BsYoutube} from 'react-icons/bs'
import { SiKofi } from "react-icons/si";
import {FaItchIo} from 'react-icons/fa'

const HeaderSocials = () => {
  return (
    <div className="header__socials">
        <a href="https://www.youtube.com/@nouhideveloper" target="_blank"><BsYoutube/></a>
        <a href="https://ko-fi.com/nouhidev" target="_blank"><SiKofi/></a>
        <a href="https://nouhidev.itch.io/" target="_blank"><FaItchIo/></a>
    </div>
  )
}

export default HeaderSocials