import React from 'react'
import './footer.css'
import {RiTwitterXLine} from 'react-icons/ri'
import {BsDiscord} from 'react-icons/bs'
import {BsYoutube} from 'react-icons/bs'
import {BsGithub} from 'react-icons/bs'
import {FaItchIo} from 'react-icons/fa'

const Footer = () => {
  return (
    <footer>
      <a href='' className='footer__logo'>NOUHIDEV</a>
      <ul className='permaLinks'>
        <li><a href="#">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#projects">Projects</a></li>
        <li><a href="#experience">Experiences</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul>

      <div className='footer__socials'>
        <a href="https://discord.gg/nouhidev" target="_blank"><BsDiscord/></a>
        <a href="https://twitter.com/nouhidev" target="_blank"><RiTwitterXLine/></a>
        <a href="https://www.youtube.com/@nouhideveloper" target="_blank"><BsYoutube/></a>
        <a href="https://github.com/NouhiDev" target="_blank"><BsGithub/></a>
        <a href="https://nouhidev.itch.io/" target="_blank"><FaItchIo/></a>
      </div>

      <div className="footer__copyright">
        <small>
          Copyright &copy; 2023, NDev-Group. All Rights Reserved.
        </small>
      </div>
    </footer>
  )
}

export default Footer