import React from 'react'
import './about.css'
import Logo from '../../assets/about4.png'
import { FaConnectdevelop } from 'react-icons/fa'
import { AiOutlineEye } from 'react-icons/ai'
import { AiOutlineDownload } from 'react-icons/ai'

const About = () => {
  return (
    <section id="about">
      <div class="horizontal"></div>
      <h5>Introduction</h5>
      <h2>About Me</h2>

      <div className='container about__container'>
        <div className="about__me">
          <div className="about__me__image">
            <img src={Logo} alt=''></img>
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className='about__card'>
              <FaConnectdevelop className='about__icon' />
              <h5>Experience</h5>
              <small>4+ Years</small>
            </article>
            <article className='about__card'>
              <AiOutlineEye className='about__icon' />
              <h5>Views</h5>
              <small>10.000.000+</small>
            </article>
            <article className='about__card'>
              <AiOutlineDownload className='about__icon' />
              <h5>Downloads</h5>
              <small>40.000+</small>
            </article>
          </div>

          <p>
            Hey, I'm nouhidev, an aspiring indie game developer based in Austria.
            <br></br>
            I'm passionate about horror games and enjoy creating and playing immersive psychological experiences.
          </p>

          <a href="https://ko-fi.com/nouhidev" className='btn btn-primary'>Support Me</a>
        </div>
      </div>
    </section>
  )
}

export default About