import React from 'react'

const CTA = () => {
  return (
    <div class="cta">
        <a href="#projects" className="btn">My Projects</a>
        <a href="#contact" className="btn btn-primary">Get in Touch</a>
    </div>
  )
}

export default CTA