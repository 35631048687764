import React from 'react'
import './experience.css'
import {BiLogoUnity} from 'react-icons/bi'
import {SiRobloxstudio} from 'react-icons/si'
import {SiUnrealengine} from 'react-icons/si'
import {SiCsharp} from 'react-icons/si'
import {SiPython} from 'react-icons/si'
import {SiLua} from 'react-icons/si'
import {SiJavascript} from 'react-icons/si'
import {SiHtml5} from 'react-icons/si'
import {SiCss3} from 'react-icons/si'

const Experience = () => {
  return (
    <section id="experience">
      <h5>What I've Learned Over the Years</h5>
      <h2>My Experiences</h2>

      <div className='container experience__container'>
        <div className="experience__gamedev">
          <h3>Game Development</h3>
          <div className="experience__content">
            <article className='experience__details'>
              <BiLogoUnity className='experience__details-icon'/>
              <div>
                <h4>Unity Engine</h4>
                <small className='text-light'>Proficient</small>
              </div>
            </article>
            <article className='experience__details'>
              <SiRobloxstudio className='experience__details-icon'/>
              <div>
                <h4>Roblox Studio</h4>
                <small className='text-light'>Intermediate</small>
              </div>
            </article>
            <article className='experience__details'>
              <SiUnrealengine className='experience__details-icon'/>
              <div>
                <h4>Unreal Engine</h4>
                <small className='text-light'>Basic</small>
              </div>
            </article>
          </div>
        </div>
        <div className="experience__dev">
          <h3>General Development</h3>
          <div className="experience__content">
          <article className='experience__details'>
              <SiCsharp className='experience__details-icon'/>
              <div>
                <h4>C#</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='experience__details'>
              <SiPython className='experience__details-icon'/>
              <div>
                <h4>Python</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='experience__details'>
              <SiLua className='experience__details-icon'/>
              <div>
                <h4>Lua</h4>
                <small className='text-light'>Intermediate</small>
              </div>
            </article>
            <article className='experience__details'>
              <SiJavascript className='experience__details-icon'/>
              <div>
                <h4>JavaScript</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='experience__details'>
              <SiHtml5 className='experience__details-icon'/>
              <div>
                <h4>HTML</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='experience__details'>
              <SiCss3 className='experience__details-icon'/>
              <div>
                <h4>CSS</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Experience