import React from 'react'
// Components
import Header from './components/header/Header'
import Nav from './components/nav/Nav'
import About from './components/about/About'
import Contact from './components/contact/Contact'
import Experience from './components/experience/Experience'
import Projects from './components/projects/Projects'
import Footer from './components/footer/Footer'

const App = () => {
  return (
    <>
        <Header/>
        <Nav/>
        <About/>
        <Projects/>
        <div class="horizontal"></div>
        <Experience/>
        <div class="horizontal"></div>
        <Contact/>
        <Footer/>
    </>
  )
}

export default App