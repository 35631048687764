import React from 'react'
import './contact.css'
import {AiOutlineMail} from 'react-icons/ai'
import {RiTwitterXLine} from 'react-icons/ri'
import {BsDiscord} from 'react-icons/bs'

const Contact = () => {
  return (
    <section id="contact">
      <h5>Get in Touch</h5>
      <h2>Contact Me</h2>

      <div className='container contact__container'>
        <div className="experience__gamedev">
          <h3>Preferred</h3>
          <div className="experience__content">
            <article className='experience__details'>
              <AiOutlineMail className='experience__details-icon'/>
              <div>
                <h4>E-Mail</h4>
                <small className='text-light'>contact@nouhi.dev</small>
              </div>
            </article>
            <article className='experience__details'>
              <BsDiscord className='experience__details-icon'/>
              <div>
                <h4>Discord</h4>
                <small className='text-light'>nouhi</small>
              </div>
            </article>
          </div>
        </div>
        <div className="experience__gamedev">
          <h3>Other</h3>
          <div className="experience__content">
            <article className='experience__details'>
              <RiTwitterXLine className='experience__details-icon'/>
              <div>
                <h4>X / Twitter</h4>
                <small className='text-light'>@nouhidev</small>
              </div>
            </article>
            <article className='experience__details'>
              <BsDiscord className='experience__details-icon'/>
              <div>
                <h4>Discord Server</h4>
                <small className='text-light'>https://discord.gg/nouhidev</small>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact